<template>
    <v-virtual-scroll
        class="cols-12"
        :bench="benched"
        :items="clusters"
        :height="scrollerHeight"
        item-height="72"
        @scroll.native="handleScroll"
        ref="virtualScroller"
    >
        <template v-slot:default="{ item }">
            <v-list-item link two-line @click.stop="selected(item)">
                <div class="d-flex align-center">
                    <v-list-item-avatar>
                        <v-icon class="grey lighten-1" dark>
                            mdi-database
                        </v-icon>
                    </v-list-item-avatar>
                </div>
                <v-list-item-content>
                    <v-list-item-title
                        v-text="`${item.Gruppo}`"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                        v-html="
                            `modificato da <b>${item.UtenteUltimaModifica}</b> il <i>${item.DataUltimaModifica}</i>`
                        "
                    ></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-chip class="ma-2" color="gray">
                        <v-avatar left class="grey lighten-1">
                            {{ item.NumeroClientiDelGruppo }}
                        </v-avatar>
                        Negozi
                    </v-chip>
                </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
        </template>
    </v-virtual-scroll>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import * as appActions from "../../store/actions/app";

export default {
    props: {
        clusters: {
            type: Array,
            required: true
        },
        scrollerHeight: {
            type: Number,
            required: true
        }
    },

    components: {},

    data: () => ({
        benched: 4
    }),

    computed: {
        ...mapState({
            getScrollPosition: state => state.appModule.farmStoresScrollPosition
        }),

        scrollPosition: {
            get() {
                return this.getScrollPosition;
            },
            set(value) {
                this.setListScrollPosition(value);
            }
        }
    },

    methods: {
        ...mapMutations({
            //resetStore: clusterActions.STORE_RESET_STORE,
            setListScrollPosition: appActions.APP_SETSTORES_SCROLLPOSITION
        }),

        selected(id) {
            this.$emit("selected", id);
        },

        handleScroll(event) {
            this.scrollPosition = event.target.scrollTop;
        }
    },

    beforeMount() {
        /* console.log(this.scrollerHeight) */
        //this.resetStore();
    },

    mounted() {
        this.$nextTick(() => {
            setTimeout(() => {
                if (this.$refs.virtualScroller !== undefined) {
                    this.$refs.virtualScroller.$el.scrollTop = this.scrollPosition;
                }
            }, 10);
        });
    }
};
</script>

<style scoped>
.v-virtual-scroll {
    padding-bottom: 128px;
}
</style>
